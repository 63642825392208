import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import FooterMenu from './FooterMenu';
import '../footer/footer.modules.css';
import useQuery from './useQuery';
import { FooterDataPropsM5 } from '../../../types/types';

const FooterM5 = () => {
	const data = useQuery();

	return (
		<div className='footer-background-image-container'>
			{/* <GatsbyImage image={backgroundImage} alt='' className='footer-background-image' /> */}
			<FooterContainer {...data} />
		</div>
	);
};
const FooterContainer = (props: FooterDataPropsM5) => {
	const {
		rightTitle,
		socialMediaIconArray,
		paymentsIconArray,
		pagesArray,
		copyRightText,
		logoImage,
		iconBackgroundColor,
		backgroundColor
	} = props;
	return (
		<div className='footer-container footer-data-container-grid' style={{ backgroundColor: backgroundColor }}>
			<div className='gatsby-custom-container'>
				<div className='gatsby-custom-row'>
					<div className='footer-upper-block'>
						<div className='footer-left'>
							<div className='footer-logo'>
								<GatsbyImage image={logoImage.imageFile} className='footer-logo-image' alt={logoImage.altText} />
							</div>

							<div className='social-images-container'>
								{socialMediaIconArray.map((icon, index) => {
									return (
										<div
											style={{ backgroundColor: iconBackgroundColor }}
											className='social-media-image-div'
											key={index}>
											<a href={icon.url} target='_blank'>
												<GatsbyImage
													image={icon.image.imageFile}
													alt={icon.image.altText}
													className='social-media-image-field'
												/>
											</a>
										</div>
									);
								})}
							</div>
						</div>
						<div className='footer-right'>
							<div className='payment-label-text'>
								<p style={{ color: rightTitle.color }} className='right-title-text footer-p-tag'>
									{rightTitle.text}
								</p>
							</div>
							<div className='image-array-payments'>
								{paymentsIconArray.map((icon, index) => {
									return (
										<GatsbyImage image={icon.imageFile} alt={icon.altText} className='payment-icon' key={index} />
									);
								})}
							</div>
						</div>
					</div>

					<div className='footer-bottom-block'>
						<div className='copyright-content-block'>
							<div className='page-list'>
								<FooterMenu pagesArray={pagesArray} />
							</div>
							<div className='copyright-text'>
								<p style={{ color: copyRightText.color }} className='footer-p-tag'>
									{copyRightText.text}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FooterM5;

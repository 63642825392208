import React from 'react'
import './style.css';
import StickyBottomBar from '../stickyDownloadButton';

export default function Popupvideo(props) {

    const closePopup = () =>{
      props.onClosePopup();
    }


  return (
    <>
    <div className='overlay_video_wrapper notshow overlay' id="overlay">
      <div className='wrapmore_video_box'>
       <div className='floatingDownloadButtonContainer'>
          <div className="FloatingDownloadButtonComponentWithVideo_floatingDownloadButton video-popup">
              <video poster='https://assets.zupee.com/playpopup-video/videothumb.png' className="videoframe" preload="none" autoPlay controls id="videoframe" >
              <source src="https://assets.zupee.com/playpopup-video/How-to-download-Zupee-Adaptive-video-com.mp4" type="video/mp4" />
              </video>
              <button id="close-btn" className='close' onClick={closePopup}></button>
          </div>
	   </div>
     </div>
     <div className='bottomsticky_popup_video_wrapper'>
        <div className='content_wrapper_video'>
           <StickyBottomBar />
        </div>
     </div>
    </div>
    </>
  )
}
import React, { useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import '../header/header.modules.css';
import useQuery from './useQuery';
import MenuModal from './MenuModal';
import { useScrollHandler } from '../../../../utils/scrollHandler';
import PageContext from '../../../context/page/pageContext';

const Header = (props) => {

	const scroll = useScrollHandler(10);

	const data = useQuery();
	const { logoImage, tabArray, backgroundColor } = data;
	const { popupThankyouRef, setPopupThankyouRef } = useContext(PageContext);
	const openThankyouPopup = () => {
		setTimeout(function () { setPopupThankyouRef(!popupThankyouRef); }, 500);
	}

	return (
		<nav className={`navBar ${scroll ? 'stickyHeader' : ''}`} style={{ backgroundColor: backgroundColor }}>
			<div className='gatsby-custom-container'>
				<div className='header-inner-container'>
					<div className='logo-image-container'>
						<a className={props?.pageName !== 'ls10page' ? 'non' : 'apk-download-link'}
							href={props?.pageName !== 'ls10page' ? logoImage.headerUrl : props?.apkUpdateLink} onClick={props?.pageName !== 'ls10page' ? undefined : openThankyouPopup}>
							<GatsbyImage className='logo-image' image={logoImage.imageFile} alt={logoImage.altText} />
						</a>
					</div>
					{props.headerLinksShow === 'false' ? '' : <div className='tabList-container'>
						{tabArray.map((tab, index) => {
							return (
								<a href={tab.url} className={'tabText'} style={{ color: tab.text.color ?? '#4611a7' }} key={index}>
									{tab.text.text}
								</a>
							);
						})}
					</div>}
				</div>
			</div>
		</nav>
	);
};

export default Header;

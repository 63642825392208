import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import PageContext from '../../../context/page/pageContext';

interface SEOProps {
	title: string;
	keyWords: string;
	canonical: string;
	robots: string;
	description: string;
	// favIcon: string;
	structuredSchemaData: Array<any>;
}
const SEOComponent = (props: SEOProps) => {

	const { page } = useContext(PageContext);
	const { title, keyWords, robots, canonical, description, structuredSchemaData } = props;
	return (
		<>
			{(page === 'ls9page' || page === 'ls10page' || page === 'ls11page' || page === 'ls13page') ?
				<Helmet htmlAttributes={{ lang: 'en-US' }}>
					<title>{title}</title>
					<meta name='description' content={description} />
					<meta name='keywords' content={keyWords} />
					<meta name='robots' content="noindex, nofollow" />
					<link rel='canonical' href={canonical} />
					<link rel='icon' type='image/png' href={"https://assets.zupee.com/downloads/assets/favicon-zup.png"} sizes='16x16' />
					<link rel='preconnect' href='https://fonts.googleapis.com' />
					<link rel='preconnect' href='https://fonts.gstatic.com' />
					<link rel="preconnect" href="https://assets.zupee.com" />
				</Helmet>
				:
				<Helmet htmlAttributes={{ lang: 'en-US' }}>
					<title>{title}</title>
					<meta name='description' content={description} />
					<meta name='keywords' content={keyWords} />
					<meta name='robots' content="noindex, nofollow" />
					<link rel='canonical' href={canonical} />
					<link rel='icon' type='image/png' href={"https://assets.zupee.com/downloads/assets/favicon-zup.png"} sizes='16x16' />
					<link rel='preconnect' href='https://fonts.googleapis.com' />
					<link rel='preconnect' href='https://fonts.gstatic.com' />
					<link rel="preconnect" href="https://www.google-analytics.com/" />
					<link rel="preconnect" href="https://www.googletagmanager.com/" />
					<link rel="preconnect" href="https://assets.zupee.com" />
				</Helmet>
			}
			{/* MS-clarity script */}
			{page === 'ls11page' &&
				<Helmet>
					<script className='ms-clarity' async type="text/javascript">{`
						(function(c,l,a,r,i,t,y){
						c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
						t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
						y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
						})(window, document, "clarity", "script", "jbvcgi4x3l");
						`}
					</script>
				</Helmet>
			}

			{page === 'ls8page' &&
			<Helmet>
				<link rel="preconnect" href={`${process.env.AUTO_REDIRECT_API_ENDPOINT}`} />
				<meta http-equiv="delegate-ch" content={`sec-ch-dpr ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-width ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-viewport-width ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-platform-version ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-full-version ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-full-version-list ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-model ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-arch ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-bitness ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				sec-ch-ua-wow64 ${process.env.AUTO_REDIRECT_API_ENDPOINT};
				ect ${process.env.AUTO_REDIRECT_API_ENDPOINT};`} />
			</Helmet>
			}

			{
				'homepagehindi' === page
				&&
				<Helmet
					style={[{
						"cssText": `@font-face{font-family:Baloo 2;font-style:normal;font-weight:500;font-display:swap;src:url(${process.env.FONT_S3_BUCKET}baloo2-500.woff2) format(woff2);unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}@font-face{font-family:Baloo 2;font-style:normal;font-weight:700;font-display:swap;src:url(${process.env.FONT_S3_BUCKET}baloo2-700.woff2) format(woff2);unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}`
					}]}
				>
					<link rel="preload" as="font" type="font/woff2" href={`${process.env.FONT_S3_BUCKET}baloo2-500.woff2`} crossOrigin="anonymous" />
					<link rel="preload" as="font" type="font/woff2" href={`${process.env.FONT_S3_BUCKET}baloo2-700.woff2`} crossOrigin="anonymous" />
				</Helmet>
			}
			{
				<Helmet
					style={[{
						"cssText": `@font-face{font-family:Inter;font-style:normal;font-weight:400;font-display:swap;src:url(${process.env.FONT_S3_BUCKET}inter-400.woff2) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}@font-face{font-family:Inter;font-style:normal;font-weight:600;font-display:swap;src:url(${process.env.FONT_S3_BUCKET}inter-600.woff2) format('woff2');unicode-range:U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD}@font-face{font-family:'SignPainter HouseSlant';font-style:normal;font-weight:400;src:url('${process.env.FONT_S3_BUCKET}SignPainter-HouseSlant-Regular.ttf') format('truetype');font-display:swap}`
					}]}
				>
					<link rel="preload" as="font" type="font/woff2" href={`${process.env.FONT_S3_BUCKET}inter-400.woff2`} crossOrigin="anonymous" />
					<link rel="preload" as="font" type="font/woff2" href={`${process.env.FONT_S3_BUCKET}inter-600.woff2`} crossOrigin="anonymous" />
					<link rel="preload" as="font" type="font/ttf" href={`${process.env.FONT_S3_BUCKET}SignPainter-HouseSlant-Regular.ttf`} crossOrigin="anonymous" />
					{
						structuredSchemaData?.map((structuredJson, index) => {
							return <script type='application/ld+json'>{structuredJson?.structuredjson}</script>;
						})}
				</Helmet>
			}

		</>
	);
};
export default SEOComponent;
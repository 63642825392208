import React from 'react';

import { TextWithUrl } from '../../../types/types';
interface FooterMenuArray {
	pagesArray: Array<TextWithUrl>;
}
const FooterMenu = (props: FooterMenuArray) => {
	const pagesArray = props.pagesArray;
	return (
		<React.Fragment>
			{pagesArray.map((page, index) => {
				return (
					<p style={{ color: page.text.color ?? '#FFF' }} key={index} className='footer-link-p-tag'>
						<a style={{ color: page.text.color ?? '#FFF' }} href={page.url} className='footer-link'>
							{page.text.text}
						</a>
						{index !== pagesArray.length - 1 && '|'}
					</p>
				);
			})}
		</React.Fragment>
	);
};

export default FooterMenu;

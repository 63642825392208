import { useEffect, useState } from 'react';

export const useScrollHandler = (scrollValue) => {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			const scrollCheck = window.scrollY > scrollValue;
			setScroll(scrollCheck);
		};
		document.addEventListener('scroll', onScroll);
		return () => document.removeEventListener('scroll', onScroll);
	}, [scroll, setScroll]);

	return scroll;
};

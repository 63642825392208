
// Pixel: PageView Script
export function PixelPageViewScript() {
  return `
(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
  {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
  a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
  r.src=n;var u=t.getElementsByTagName(s)[0];
  u.parentNode.insertBefore(r,u);})(window,document,
  'https://sc-static.net/scevent.min.js');
  
  snaptr('init', '0149b93b-0425-4eae-bd53-e638403c21f6', {
  'user_email': '__INSERT_USER_EMAIL__'
  });
  
  snaptr('track', 'PAGE_VIEW');
`
}

// Pixel: Purchase Script
export function PixelPurchaseScript() {
  return `
  (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
    r.src=n;var u=t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r,u);})(window,document,
    'https://sc-static.net/scevent.min.js');
    
    snaptr('init', '0149b93b-0425-4eae-bd53-e638403c21f6', {
    'user_email': '__INSERT_USER_EMAIL__'
    });
    
    snaptr('track','PURCHASE');    
  `
}
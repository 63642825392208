// Append Script

const isInArray = (array, item)=>{
  for(var i = 0; i < array.length; i++) 
  return (array[i] === item) ? true: false;
}

export const appendScript = (scriptToAppend, id) => {
  let allScripts=document.getElementsByTagName("script");
  if(!isInArray(allScripts, id)){
    const script = document.createElement("script");
    script.append(scriptToAppend);
    script.id = id;
    document.head.appendChild(script);
  }
}
import { graphql, useStaticQuery } from "gatsby"
import { StickyDownloadNotificationProps } from '../../../types/types';

export const processStickyDownloadNotificationData = (data: any): StickyDownloadNotificationProps => {
	const myData = data.allWpPage.edges[0].node.stickydownloadnotification;
  const stickyDownloadNotificationData: StickyDownloadNotificationProps = {
		stickyNotificationImg: {
			imageFile: myData.stickynotificationimg?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData.stickynotificationimg?.alttext
		},
		stickyNotificationDownloadIcon: {
			imageFile: myData.stickynotificationdownloadicon?.imagefile?.localFile?.childImageSharp?.gatsbyImageData,
			altText: myData.stickynotificationdownloadicon?.alttext
    },
		titleText: myData.titletext,
		titleTextHindi: myData.titletexthindi,
		titleTextBengali: myData.titletextbengali,
		titleTextLs14Oriya: myData.titletextls14oriya,
		titleTextLs14Punjabi: myData.titletextls14punjabi,
		titleTextLs14Malayalam: myData.titletextls14malayalam,
		titleTextKannada: myData.titletextkannada,
		titleTextTamil: myData.titletexttamil,
		titleTextBhojpuri: myData.titletextbhojpuri,
		titleTextGujarati: myData.titletextgujarati,
		titleTextMarathi: myData.titletextmarathi,
		subtitleText: myData.subtitletext,
		subtitleTextHindi: myData.subtitletexthindi,
		subtitleTextBengali: myData.subtitletextbengali,
		subtitleTextLs14Oriya: myData.subtitletextls14oriya,
		subtitleTextLs14Punjabi: myData.subtitletextls14punjabi,
		subtitleTextLs14Malayalam: myData.subtitletextls14malayalam,
		subtitleTextKannada: myData.subtitletextkannada,
		subtitleTextTamil: myData.subtitletexttamil,
		subtitleTextBhojpuri: myData.subtitletextbhojpuri,
		subtitleTextGujarati: myData.subtitletextgujarati,
		subtitleTextMarathi: myData.subtitletextmarathi,
	};
	return stickyDownloadNotificationData;
};

const useQuery = (): StickyDownloadNotificationProps => {
  const data = processStickyDownloadNotificationData(
    useStaticQuery(graphql
			`{
				allWpPage(filter: {uri: {eq: "/sticky-download-notification/"}}) {
					edges {
						node {
							stickydownloadnotification {
								subtitletext {
									color
									text
								}
								subtitletexthindi {
									color
									text
								}
								subtitletextbengali {
									color
									text
								}
								subtitletextls14oriya {
									color
									text
								}
								subtitletextls14punjabi {
									color
									text
								}
								subtitletextls14malayalam {
									color
									text
								}
								subtitletextkannada {
									color
									text
								}
								subtitletexttamil {
									color
									text
								}
								subtitletextbhojpuri {
									color
									text
								}
								subtitletextgujarati {
									color
									text
								}
								subtitletextmarathi {
									color
									text
								}
								titletext {
									color
									text
								}
								titletexthindi {
									color
									text
								}
								titletextbengali {
									color
									text
								}
								titletextls14oriya {
									color
									text
								}
								titletextls14punjabi {
									color
									text
								}
								titletextls14malayalam {
									color
									text
								}
								titletextkannada {
									color
									text
								}
								titletexttamil {
									color
									text
								}
								titletextbhojpuri {
									color
									text
								}
								titletextgujarati {
									color
									text
								}
								titletextmarathi {
									color
									text
								}
								stickynotificationimg {
									alttext
	
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
								stickynotificationdownloadicon {
									alttext
	
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
							}
						}
					}
				}
			}
    `)
  );
  return data;
};
export default useQuery;
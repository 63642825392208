import { graphql, useStaticQuery } from "gatsby"
import { FooterDataPropsM5, ImageWithUrl, TextWithUrl } from "../../../types/types"

const processFooterData = (data: any): FooterDataPropsM5 => {
  const myData = data.allWpPage.edges[0].node.footerFieldsM5

  const socialiconArray = myData.socialmediaiconarraym5
  const socialMediaIconsData = []
  socialiconArray.map((iconData, index) => {
    const image = { imageFile: iconData?.images?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: iconData?.images?.image?.alttext }
    const IconObject: ImageWithUrl = {
      image: image,
      url: iconData.images.url,

    }
    socialMediaIconsData.push(IconObject)
  })

  const paymentIconsArray = []
  const paymentArray = myData.paymentsiconarraym5
  paymentArray.map((imageObj, index) => {
    const image = { imageFile: imageObj?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: imageObj?.image?.alttext }
    paymentIconsArray.push(image)
  })

  const pageArray = []
  const menu = myData?.pagesarraym5
  menu.map((pageLinkData, index) => {
    const menuItemObject: TextWithUrl = {
      text: { text: pageLinkData?.textwithurl?.text, color: pageLinkData?.textWithUrl?.text?.color ?? "#FFF" },
      url: pageLinkData?.textwithurl.url
    }
    pageArray.push(menuItemObject);
  })

  const footerData: FooterDataPropsM5 = {
    logoImage: { 
      imageFile: myData?.logoimagem5?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, 
      altText: myData?.logoimagem5?.alttext
    },
    rightTitle: { 
      text: myData.righttitlem5.title, 
      color: myData.righttitlem5.color
    },
    copyRightText: myData.copyrighttextm5,
    paymentsIconArray: paymentIconsArray,
    socialMediaIconArray: socialMediaIconsData,
    pagesArray: pageArray,
    iconBackgroundColor: myData.socialmediaiconbackgroundcolorm5,
    backgroundColor: myData.backgroundm5
  }
  return footerData
}

export const useQuery = (): FooterDataPropsM5 => {
  const data = processFooterData(
    useStaticQuery(graphql`
        {
            allWpPage(filter: {uri: {eq: "/footerm5/"}}) {
              edges {
                node {
                  id
                  uri
                  footerFieldsM5 {
                    copyrighttextm5 {
                      color
                      text
                    }
                    righttitlem5 {
                      color
                      title
                    }
                    pagesarraym5 {
                      textwithurl {
                        url
                        text
                      }
                    }
                    socialmediaiconbackgroundcolorm5
                    paymentsiconarraym5 {
                      image {
                        alttext
                        imagefile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                    }
                    socialmediaiconarraym5 {
                      images {
                        image {
                          alttext
                          imagefile {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                          }
                        }
                        url
                      }
                    }
                    logoimagem5 {
                      alttext
                      imagefile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                    backgroundm5
                  }
                }
              }
            }
          }
          
          
		`)
  );
  return data;
};

export default useQuery;
import React from 'react';

interface IPageContext {
	page: string;
	data: any;
	downloadLink: string;
	pageRelatedTo: string;
	showCheckboxToDownload:boolean;
	apkPromise: any;
	gclidValue: string;
	andriodLinkRef: string;
	fallbackCase: boolean;
	popupThankyouRef: boolean;
	popupShowRef:boolean;
	setPopupShowRef: React.Dispatch<React.SetStateAction<boolean>>;
	setPopupThankyouRef: React.Dispatch<React.SetStateAction<boolean>>;
	userBucket: string;
	gatsbyDownloadBtnEvent: string;
}

const PageContext = React.createContext<IPageContext>({} as IPageContext);

export default PageContext;

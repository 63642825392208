import { graphql, useStaticQuery } from "gatsby"
import { HeaderDataM5, TextWithUrl } from "../../../types/types"
export const processHeaderData = (data: any): HeaderDataM5 => {
  const myData = data.allWpPage.edges[0].node.headerFieldsM5
  
  const tabs = myData.tabsm5
  const tabArray = []
  tabs.forEach((tab, index) => {
    const textWithUrl: TextWithUrl = {
      text: { text: tab.textwithurl.text, color: "#4611a7" },
      url: tab.textwithurl.url
    }
    tabArray.push(textWithUrl)
  })
  const headerData: HeaderDataM5 = {
    logoImage: { 
      imageFile: myData?.headerlogoimagem5?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, 
      altText: myData?.headerlogoimagem5?.alttext, 
      headerUrl: myData?.headerlogoimagem5?.headerurl
    },
    textColor: myData?.menucolor,
    tabArray: tabArray,
    // closeIcon: myData?.closeicon?.localFile?.childImageSharp?.gatsbyImageData,
    // hamIcon: myData?.hamburgericon?.localFile?.childImageSharp?.gatsbyImageData,
    backgroundColor: myData?.backgroundcolorm5,
    backgroundImage: myData?.background?.image?.localFile?.childImageSharp?.gatsbyImageData
  }
  
  return headerData
}
const useQuery = (): HeaderDataM5 => {
  const data = processHeaderData(
    useStaticQuery(graphql`
        {
            allWpPage(filter: {uri: {eq: "/headerm5/"}}) {
              edges {
                node {
                  headerFieldsM5 {
                    backgroundcolorm5
                    tabsm5 {
                      textwithurl {
                        text
                        url
                      }
                    }
                    headerlogoimagem5 {
                      headerurl
                      alttext
                      imagefile {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
    `)
  );
  return data;
};
export default useQuery;
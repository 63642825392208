import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import './stickyDownloadNotification.modules.css';

import { StickyDownloadNotificationProps } from '../../../types/types';
import useQuery from './useQuery';
import PageContext from '../../../context/page/pageContext';

const StickyDownloadNotificationComponent = () => {
	const data = useQuery();
	return (
		<>
			<StickyDownloadNotification {...data} />
		</>
	);
};

const StickyDownloadNotification = (props: StickyDownloadNotificationProps) => {
	const { pageRelatedTo } = useContext(PageContext);
	const {
		titleText,
		titleTextHindi,
		titleTextBengali,
		titleTextKannada,
		titleTextTamil,
		titleTextBhojpuri,
		titleTextGujarati,
		titleTextMarathi,
		titleTextLs14Oriya,
		titleTextLs14Punjabi,
		titleTextLs14Malayalam,
		subtitleText,
		subtitleTextHindi,
		subtitleTextBengali,
		subtitleTextKannada,
		subtitleTextLs14Oriya,
		subtitleTextLs14Punjabi,
		subtitleTextLs14Malayalam,
		subtitleTextTamil,
		subtitleTextBhojpuri,
		subtitleTextGujarati,
		subtitleTextMarathi,
		stickyNotificationImg,
		stickyNotificationDownloadIcon
	} = props;
	return (
		<>
			<div className='sticky-download-notification-container'>
				<div className='sticky-download-notification-content'>
					<div className='main-notification-img-box'>
						<GatsbyImage
							image={stickyNotificationImg.imageFile}
							alt={stickyNotificationImg.altText}
							className='notification-image'
						/>
					</div>
					<div className='content-box'>
						<div className='heading-text' style={{ color: titleText.color }}>
							{pageRelatedTo === 'homepagehindi' || pageRelatedTo === 'ls4hindipage' || pageRelatedTo === 'homepagehindi14' ? (
								<>{titleTextHindi.text}</>
							) : (
								<>
									{pageRelatedTo === 'lsbengalipage' || pageRelatedTo === 'ls14bengalipage' ? (
										<>{titleTextBengali.text}</>
									) : (
										<>
											{pageRelatedTo === 'ls14oriyapage' ? (
												<>{titleTextLs14Oriya.text}</>
												) : (
													<>
														{pageRelatedTo === 'ls14punjabipage' ? (
															<>{titleTextLs14Punjabi.text}</>
															) : (
																<>
																	{pageRelatedTo === 'ls14malayalampage' ? (
																		<>{titleTextLs14Malayalam.text}</>
											) : (
												<>
													{pageRelatedTo === 'lskannadapage' || pageRelatedTo === 'ls14kannadapage' ? (
														<>{titleTextKannada.text}</>
													) : (
														<>
															{pageRelatedTo === 'lstamilpage' ||
																pageRelatedTo === 'ls14tamilpage' ? (
																<>{titleTextTamil.text}</>
															) : (
																<>
																	{pageRelatedTo === 'lsbhojpuripage' || pageRelatedTo === 'ls14bhojpuripage' ? (
																		<>{titleTextBhojpuri.text}</>
																	) : (
																		<>
																			{pageRelatedTo === 'lsgujaratipage' || pageRelatedTo === 'ls14gujaratipage' ? (
																				<>{titleTextGujarati.text}</>
																			) : (
																				<>
																					{pageRelatedTo === 'lsmarathipage' || pageRelatedTo === 'ls14marathipage' ? (
																						<>{titleTextMarathi.text}</>
																					) : (
																						<>{titleText.text}</>
																					)}
																				</>
																			)}
																		</>
																	)}
																</>
															)}
														</>
													)}
												</>
											)}
										</>
								)}
								</>
							)}
						</>
						)}
						</>
							)}
						</div>
						<div className='bottom-box'>
							<div className='icon-box'>
								<GatsbyImage
									image={stickyNotificationDownloadIcon.imageFile}
									alt={stickyNotificationDownloadIcon.altText}
									className='download-icon'
								/>
							</div>
							<div className='subheading-text' style={{ color: subtitleText.color }}>
								{pageRelatedTo === 'homepagehindi' || pageRelatedTo === 'ls4hindipage' || pageRelatedTo === 'homepagehindi14' ? (
									<>{subtitleTextHindi.text}</>
								) : (
									<>
										{pageRelatedTo === 'lsbengalipage' || pageRelatedTo === 'ls14bengalipage' ? (
											<>{subtitleTextBengali.text}</>
										) : (
											<>
												{pageRelatedTo === 'ls14oriyapage' ? (
													<>{subtitleTextLs14Oriya.text}</>
													) : (
														<>
															{pageRelatedTo === 'ls14punjabipage' ? (
																<>{subtitleTextLs14Punjabi.text}</>
																) : (
																	<>
																		{pageRelatedTo === 'ls14malayalampage' ? (
																			<>{subtitleTextLs14Malayalam.text}</>
												) : (
													<>
														{pageRelatedTo === 'lskannadapage' || pageRelatedTo === 'ls14kannadapage' ? (
															<>{subtitleTextKannada.text}</>
														) : (
															<>
																{pageRelatedTo === 'lstamilpage' || pageRelatedTo === 'ls14tamilpage' ? (
																	<>{subtitleTextTamil.text}</>
																) : (
																	<>
																		{pageRelatedTo === 'lsbhojpuripage' || pageRelatedTo === 'ls14bhojpuripage' ? (
																			<>{subtitleTextBhojpuri.text}</>
																		) : (
																			<>
																				{pageRelatedTo === 'lsgujaratipage' || pageRelatedTo === 'ls14gujaratipage' ? (
																					<>{subtitleTextGujarati.text}</>
																				) : (
																					<>
																						{pageRelatedTo === 'lsmarathipage' || pageRelatedTo === 'ls14marathipage' ? (
																							<>{subtitleTextMarathi.text}</>
																						) : (
																							<>{subtitleText.text}</>
																						)}
																					</>
																				)}
																			</>
																		)}
																	</>
																)}
															</>
														)}
													</>
												)}
											</>
										)}
									</>
								)}
							</>
						)}
						</>
					)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default StickyDownloadNotificationComponent;
